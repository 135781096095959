const baseURL = process.env.REACT_APP_URL

const urls = {
  auth: {
    login: `/auth`,
    refresh: `/auth/refresh`,
    activateUser: `/auth/activate`,
  },
  orders: `/orders`,
  adminProfile: `/users/my`,
  groups: `/groups`,
  users: `/admin/users`,
  ordersStatistic: `/admin/statistic/orders`,
  userStatistic: `/admin/statistic/users`,
}

export { baseURL, urls }
