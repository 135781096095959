import { ActivateUserForm } from "../../components";

import "./activateUserPage.scss";

const ActivateUserPage = () => {
    return (
        <div className={"activate-user-page"}>
            <ActivateUserForm />
        </div>
    );
};

export { ActivateUserPage };
